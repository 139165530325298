import { useState, useEffect } from 'react';
import { useSettings } from '../../contexts/SettingsContext';
import { Game } from '../../types';

export function GameGrid() {
  const { settings } = useSettings();
  const [visibleItems, setVisibleItems] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setVisibleItems(prev => Math.min(prev + 2, settings.games.length));
    }, 100);

    return () => clearInterval(timer);
  }, [settings.games.length]);

  return (
    <div className="h-[calc(90vh-200px)] overflow-y-auto scroll-smooth px-2 no-scrollbar">
      <div className="grid grid-cols-2 gap-3 pb-6">
        {settings.games.map((game: Game, index) => (
          <div
            key={game.id}
            className={`transform transition-all duration-500 ${
              index < visibleItems 
                ? 'translate-y-0 opacity-100'
                : 'translate-y-20 opacity-0'
            }`}
            style={{ transitionDelay: `${index * 50}ms` }}
          >
            <GameCard game={game} />
          </div>
        ))}
      </div>
    </div>
  );
}

interface GameCardProps {
  game: Game;
}

function GameCard({ game }: GameCardProps) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <a 
      href={game.agentLink} 
      target="_blank" 
      rel="noopener noreferrer"
      className="block transform transition-all duration-300 active:scale-95 touch-manipulation"
    >
      <div className="relative aspect-square rounded-xl overflow-hidden bg-gradient-to-br from-purple-500/10 to-pink-500/10 shadow-lg group">
        {!isImageLoaded && (
          <div className="absolute inset-0">
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent animate-shimmer" />
            <div className="absolute inset-0 bg-black/20 backdrop-blur-sm" />
          </div>
        )}
        
        <div className="relative w-full h-full">
          <div className="absolute inset-0 flex items-center justify-center bg-black/20">
            <img 
              src={game.imageUrl} 
              alt={game.name}
              onLoad={() => setIsImageLoaded(true)}
              className={`w-full h-full transition-all duration-700 ${
                isImageLoaded 
                  ? 'opacity-100 scale-100' 
                  : 'opacity-0 scale-105'
              } group-hover:scale-110 group-hover:rotate-1 ${
                game.imageUrl.includes('225x225') 
                  ? 'object-cover'
                  : game.imageUrl.includes('1024x600') || game.imageUrl.includes('600x600')
                  ? 'object-contain p-2'
                  : 'object-cover'
              }`}
              style={{
                backgroundColor: 'rgba(0,0,0,0.2)'
              }}
            />
          </div>

          <div className="absolute inset-0">
            <div className="absolute inset-x-0 top-0 h-20 bg-gradient-to-b from-black/40 to-transparent" />
            <div className="absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-black/90 via-black/60 to-transparent" />
          </div>
        </div>

        {game.type === 'video' && (
          <div className="absolute top-2 right-2 transform group-hover:-translate-y-0.5 transition-all duration-300">
            <span className="bg-gradient-to-r from-purple-500 to-pink-500 text-white text-xs px-2.5 py-1 rounded-full font-medium shadow-lg">
              LIVE
            </span>
          </div>
        )}

        <div className="absolute inset-x-0 bottom-0 px-2 pb-3">
          <div className="relative">
            <div className="flex flex-col items-center">
              <span className="text-white text-sm font-medium leading-tight text-center px-1 py-0.5 rounded-md bg-black/40 backdrop-blur-sm">
                {game.name}
              </span>
              <div className="mt-1 h-[1px] w-0 bg-purple-500/50 transition-all duration-300 group-hover:w-16" />
            </div>
          </div>
        </div>

        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-all duration-300">
          <div className="absolute inset-0 bg-gradient-to-t from-purple-500/20 to-pink-500/20" />
          <div className="absolute inset-0 bg-black/10 backdrop-blur-[1px]" />
        </div>
      </div>
    </a>
  );
}