import { useCallback } from 'react';
import { supabase } from '../lib/supabase';

interface Game {
  id: string;
  name: string;
  imageUrl: string;
}

interface Settings {
  whatsappNumber: string;
  telegramUsername: string;
  carouselImages: string[];
  games: Game[];
}

export function useSupabase() {
  const getSettings = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('settings')
        .select('*')
        .eq('id', 'main')
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error fetching settings:', error);
      return null;
    }
  }, []);

  const saveSettings = useCallback(async (settings: Settings) => {
    try {
      const { error } = await supabase
        .from('settings')
        .update(settings)
        .eq('id', 'main');

      if (error) throw error;
    } catch (error) {
      console.error('Error saving settings:', error);
      throw error;
    }
  }, []);

  return { getSettings, saveSettings };
} 