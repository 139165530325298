import { SocialButtons } from './SocialButtons';
import { useState, useEffect } from 'react';
import { useSettings } from '../../contexts/SettingsContext';

export function Header() {
  const { settings } = useSettings();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const timer = setInterval(() => {
      const validImages = settings.carouselImages.filter(url => url);
      if (validImages.length <= 1) return;

      setIsTransitioning(true);
      setCurrentImageIndex((prevIndex) => 
        prevIndex >= validImages.length - 1 ? 0 : prevIndex + 1
      );
      
      setTimeout(() => {
        setIsTransitioning(false);
      }, 500);
    }, 3000);

    return () => clearInterval(timer);
  }, [settings.carouselImages]);

  const validCarouselImages = settings.carouselImages.filter(url => url);

  return (
    <div className="relative space-y-1 pt-0.5">
      <div 
        className={`transform transition-all duration-1000 ${
          isVisible ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'
        }`}
      >
        <div className="flex justify-center items-center">
          <div className="relative px-3 py-0.5">
            <div className="text-2xl sm:text-3xl font-black tracking-tighter">
              <span className="bg-gradient-to-r from-purple-400 via-pink-500 to-orange-500 text-transparent bg-clip-text animate-gradient">
                HALEEMAU
              </span>
            </div>
            <div className="absolute -bottom-0.5 left-1/2 -translate-x-1/2 w-0 h-[2px] bg-gradient-to-r from-purple-400 via-pink-500 to-orange-500" />
          </div>
        </div>
      </div>

      {validCarouselImages.length > 0 && (
        <div 
          className={`relative h-44 sm:h-52 overflow-hidden rounded-2xl shadow-xl transform transition-all duration-1000 mx-2 ${
            isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
          }`}
        >
          {validCarouselImages.map((imageUrl, index) => (
            <div
              key={imageUrl}
              className={`absolute inset-0 transition-all duration-500 ${
                index === currentImageIndex 
                  ? 'opacity-100 z-10' 
                  : 'opacity-0 z-0'
              }`}
              style={{
                transform: isTransitioning ? 'scale(1.05)' : 'scale(1)',
                transition: 'all 500ms ease-in-out'
              }}
            >
              <div className="relative w-full h-full bg-black/20">
                <img
                  src={imageUrl}
                  alt={`Banner ${index + 1}`}
                  className="absolute w-full h-full object-contain object-center"
                />
                <div 
                  className="absolute inset-0 -z-10"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'blur(20px) brightness(0.5)',
                    transform: 'scale(1.1)'
                  }}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/20 to-black/40" />
                <div className="absolute inset-0 bg-gradient-to-b from-black/40 via-transparent to-black/60" />
                <div className="absolute inset-0 bg-gradient-to-r from-black/40 via-transparent to-black/40" />
              </div>
            </div>
          ))}
          
          <div className="absolute bottom-3 left-1/2 -translate-x-1/2 flex gap-1.5 z-20">
            {validCarouselImages.map((_, index) => (
              <button
                key={index}
                className={`h-1 rounded-full transition-all duration-300 ${
                  index === currentImageIndex 
                    ? 'bg-white w-6' 
                    : 'bg-white/40 w-2 hover:bg-white/60'
                }`}
                onClick={() => {
                  setIsTransitioning(true);
                  setCurrentImageIndex(index);
                  setTimeout(() => setIsTransitioning(false), 500);
                }}
              />
            ))}
          </div>
        </div>
      )}

      <div 
        className={`transform transition-all duration-1000 delay-300 px-2 ${
          isVisible ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}
      >
        <SocialButtons />
      </div>
    </div>
  );
}