import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useSupabase } from '../hooks/useSupabase';

interface Settings {
  whatsappNumber: string;
  telegramUsername: string;
  carouselImages: string[];
  games: any[];
  attentionMessages?: string[];
}

interface SettingsContextType {
  settings: Settings;
  isLoading: boolean;
  refetchSettings: () => Promise<void>;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

export function SettingsProvider({ children }: { children: ReactNode }) {
  const [settings, setSettings] = useState<Settings>({
    whatsappNumber: '',
    telegramUsername: '',
    carouselImages: ['', '', ''],
    games: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const { getSettings } = useSupabase();

  const fetchSettings = async () => {
    try {
      const savedSettings = await getSettings();
      if (savedSettings) {
        setSettings(savedSettings);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const refetchSettings = async () => {
    setIsLoading(true);
    await fetchSettings();
  };

  return (
    <SettingsContext.Provider value={{ settings, isLoading, refetchSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
} 