interface MainLayoutProps {
  children: React.ReactNode;
}

export function MainLayout({ children }: MainLayoutProps) {
  return (
    <div className="min-h-screen bg-[#0D0D0D] flex justify-center px-2 sm:px-4 relative overflow-hidden">
      {/* Animated background gradients */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -inset-[10px] opacity-50">
          <div className="absolute top-[-10%] -left-[25%] w-[500px] h-[500px] rounded-full bg-purple-500/20 blur-[120px] animate-pulse" />
          <div className="absolute top-[20%] -right-[25%] w-[400px] h-[400px] rounded-full bg-pink-500/20 blur-[120px] animate-pulse delay-1000" />
          <div className="absolute bottom-[-10%] left-[20%] w-[600px] h-[600px] rounded-full bg-blue-500/20 blur-[120px] animate-pulse delay-2000" />
        </div>
      </div>

      {/* Content container */}
      <div className="w-full max-w-[510px] relative">
        <div className="absolute inset-0 backdrop-blur-[100px] pointer-events-none" />
        <div className="relative">
          {/* Glass effect container */}
          <div className="relative backdrop-blur-sm bg-black/10 min-h-screen rounded-2xl shadow-2xl">
            {children}
          </div>
        </div>
      </div>

      {/* Noise texture overlay */}
      <div className="fixed inset-0 pointer-events-none opacity-[0.015] bg-[url('/noise.png')] bg-repeat" />
    </div>
  );
} 