import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useEffect } from 'react';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
    const { isAuthenticated, loading } = useAuth();
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!loading && !isAuthenticated) {
        navigate('/login', { replace: true });
      }
    }, [loading, isAuthenticated, navigate]);
  
    if (loading) {
      return (
        <div className="min-h-screen bg-gradient-to-b from-[#1E1E1E] to-[#121212] flex items-center justify-center">
          <div className="text-white animate-pulse">Loading...</div>
        </div>
      );
    }
  
    return isAuthenticated ? <>{children}</> : null;
} 