import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../hooks/useSupabase';
import { useAuth } from '../hooks/useAuth';
import { supabase } from '../lib/supabase';
import { Game, Settings } from '../types';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Progress } from "@/components/ui/progress";
import { LogOut, Upload, Plus, Trash2 } from 'lucide-react';
import { AttentionMessagesSection } from '../components/admin/AttentionMessagesSection';

export function AdminPage() {
  const [settings, setSettings] = useState<Settings>({
    whatsappNumber: '',
    telegramUsername: '',
    carouselImages: [],
    games: [],
    attentionMessages: []
  });
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'success' | 'error' | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<Record<number, number>>({});
  const [expandedGameIndex, setExpandedGameIndex] = useState<number | null>(null);
  
  const navigate = useNavigate();
  const { saveSettings, getSettings } = useSupabase();
  const { logout, isAuthenticated, loading } = useAuth();

  useEffect(() => {
    if (!loading && !isAuthenticated) {
      navigate('/login', { replace: true });
      return;
    }

    if (isAuthenticated) {
      const loadSettings = async () => {
        const savedSettings = await getSettings();
        if (savedSettings) {
          setSettings(savedSettings as Settings);
        }
      };
      loadSettings();
    }
  }, [isAuthenticated, loading, navigate]);

  useEffect(() => {
    if (saveStatus) {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const handleSave = async () => {
    setIsSaving(true);
    setSaveStatus(null);
    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        throw new Error('Not authenticated');
      }

      await saveSettings(settings);
      setSaveStatus('success');
    } catch (error) {
      setSaveStatus('error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      // Error handling without console.log
    }
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(prev => ({ ...prev, [index]: 0 }));

    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        throw new Error('Not authenticated');
      }

      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}_${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('carousel')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('carousel')
        .getPublicUrl(filePath);
      
      setSettings(prev => {
        const newCarouselImages = [...prev.carouselImages];
        newCarouselImages[index] = publicUrl;
        return { ...prev, carouselImages: newCarouselImages };
      });

      setUploadProgress(prev => ({ ...prev, [index]: 100 }));
    } catch (error) {
      alert('Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const removeImage = (index: number) => {
    setSettings(prev => ({
      ...prev,
      carouselImages: prev.carouselImages.filter((_, i) => i !== index)
    }));
  };

  const addNewImage = () => {
    setSettings(prev => ({
      ...prev,
      carouselImages: [...prev.carouselImages, '']
    }));
  };

  const handleGameChange = (index: number, field: keyof Omit<Game, 'type'>, value: string | boolean) => {
    setSettings(prev => {
      const updatedGames = [...prev.games];
      updatedGames[index] = { ...updatedGames[index], [field]: value };
      return { ...prev, games: updatedGames };
    });
  };

  const handleGameImageUpload = async (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (!file) return;

    setIsUploading(true);
    setUploadProgress(prev => ({ ...prev, [index]: 0 }));

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('Not authenticated');

      const fileExt = file.name.split('.').pop();
      const fileName = `${Date.now()}_${Math.random()}.${fileExt}`;
      const filePath = `games/${fileName}`;

      const { error: uploadError } = await supabase.storage
        .from('games')
        .upload(filePath, file, { cacheControl: '3600', upsert: false });

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('games')
        .getPublicUrl(filePath);

      setSettings(prev => {
        const updatedGames = [...prev.games];
        updatedGames[index] = { ...updatedGames[index], imageUrl: publicUrl };
        return { ...prev, games: updatedGames };
      });

      setUploadProgress(prev => ({ ...prev, [index]: 100 }));
    } catch (error) {
      alert('Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const toggleGameExpansion = (index: number) => {
    setExpandedGameIndex(prevIndex => (prevIndex === index ? null : index));
  };

  const addNewGame = () => {
    setSettings(prev => {
      const newGames = [...prev.games, {
        id: crypto.randomUUID(),
        name: '',
        imageUrl: '',
        type: 'regular' as 'regular' | 'video'
      }];
      return { ...prev, games: newGames };
    });
    setExpandedGameIndex(settings.games.length);
  };

  const removeGame = (index: number) => {
    setSettings(prev => ({
      ...prev,
      games: prev.games.filter((_, i) => i !== index)
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-gray-100">
      <div className="sticky top-0 z-50 bg-gray-800 shadow-md max-w-[510px] mx-auto">
        <div className="flex justify-between items-center p-2">
          <h1 className="text-xl font-bold">Admin Dashboard</h1>
          <Button variant="ghost" size="sm" onClick={handleLogout}>
            <LogOut className="h-4 w-4" />
          </Button>
        </div>
      </div>

      <div className="p-2 space-y-4 max-w-[510px] mx-auto">
        <Tabs defaultValue="contact" className="space-y-4">
          <TabsList className="w-full grid grid-cols-4 gap-1">
            <TabsTrigger value="contact">Contact</TabsTrigger>
            <TabsTrigger value="carousel">Carousel</TabsTrigger>
            <TabsTrigger value="games">Games</TabsTrigger>
            <TabsTrigger value="messages">Messages</TabsTrigger>
          </TabsList>

          <TabsContent value="contact">
            <Card className="bg-gray-800 shadow-lg">
              <CardHeader>
                <CardTitle>Contact Information</CardTitle>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="whatsapp">WhatsApp Number</Label>
                  <Input
                    id="whatsapp"
                    value={settings.whatsappNumber}
                    onChange={(e) => setSettings(prev => ({ ...prev, whatsappNumber: e.target.value }))}
                    placeholder="+1234567890"
                    className="h-10 bg-gray-700 text-gray-100"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="telegram">Telegram Username</Label>
                  <Input
                    id="telegram"
                    value={settings.telegramUsername}
                    onChange={(e) => setSettings(prev => ({ ...prev, telegramUsername: e.target.value }))}
                    placeholder="username (without @)"
                    className="h-10 bg-gray-700 text-gray-100"
                  />
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="carousel">
            <Card className="bg-gray-800 shadow-lg">
              <CardHeader>
                <CardTitle>Carousel Images</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4 h-[calc(100vh-350px)] overflow-y-auto">
                {settings.carouselImages.length === 0 ? (
                  <div className="text-center p-4 border-2 border-dashed border-gray-600 rounded-lg">
                    <Upload className="mx-auto h-10 w-10 text-gray-500" />
                    <h3 className="mt-1 text-xs font-semibold text-gray-400">No images uploaded</h3>
                    <p className="text-xs text-gray-500">Upload your first image to get started</p>
                    <Button variant="secondary" className="mt-2" onClick={() => document.getElementById('upload-first')?.click()}>
                      Upload First Image
                      <input
                        id="upload-first"
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 0)}
                      />
                    </Button>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 gap-2">
                    {settings.carouselImages.map((imageUrl, index) => (
                      <div key={index} className="relative rounded-lg overflow-hidden shadow-md">
                        {imageUrl ? (
                          <>
                            <img
                              src={imageUrl}
                              alt={`Carousel ${index + 1}`}
                              className="w-full aspect-[16/9] object-cover"
                            />
                            <div className="absolute top-1 right-1 bg-red-500">
                              <Button
                                variant="destructive"
                                size="sm"
                                onClick={() => removeImage(index)}
                              >
                                <Trash2 className="h-3 w-3 " />
                              </Button>
                            </div>
                          </>
                        ) : (
                          <label className="flex flex-col items-center justify-center w-full h-28 border-2 border-dashed rounded-lg cursor-pointer hover:border-primary">
                            <div className="flex flex-col items-center justify-center pt-3 pb-4">
                              <Upload className="h-5 w-5 mb-1 text-gray-500" />
                              <p className="text-xs text-gray-500">Tap to upload</p>
                            </div>
                            <input
                              type="file"
                              className="hidden"
                              accept="image/*"
                              onChange={(e) => handleImageUpload(e, index)}
                            />
                          </label>
                        )}
                        {uploadProgress[index] !== undefined && uploadProgress[index] < 100 && (
                          <Progress value={uploadProgress[index]} className="mt-1" />
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {settings.carouselImages.length < 10 && (
                  <Button 
                    variant="outline" 
                    onClick={addNewImage} 
                    className="w-full h-10"
                    disabled={isUploading}
                  >
                    <Plus className="h-3 w-3 mr-1" />
                    Add Image
                  </Button>
                )}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="games">
            <Card className="bg-gray-800 shadow-lg">
              <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle>Games Management</CardTitle>
                <Button onClick={addNewGame}>
                  <Plus className="h-3 w-3 mr-1" />
                  Add Game
                </Button>
              </CardHeader>
              <CardContent className="h-[calc(100vh-350px)] overflow-y-auto">
                <div className="space-y-2">
                  {settings.games.map((game, index) => (
                    <Card key={game.id} className="bg-gray-700 shadow-md">
                      <CardContent className="p-2 space-y-2">
                        <div className="flex justify-between items-center">
                          <h3 className="font-semibold">Game {index + 1}</h3>
                          <div className="flex space-x-2">
                            <Button variant="outline" size="sm" onClick={() => toggleGameExpansion(index)}>
                              {expandedGameIndex === index ? 'Hide' : 'Expand'}
                            </Button>
                            <Button variant="destructive" size="sm" onClick={() => removeGame(index)}>
                              <Trash2 className="h-3 w-3" />
                            </Button>
                          </div>
                        </div>
                        {expandedGameIndex === index && (
                          <div className="grid gap-2">
                            <div className="space-y-1">
                              <Label>Game Name</Label>
                              <Input
                                value={game.name}
                                onChange={(e) => handleGameChange(index, 'name', e.target.value)}
                                placeholder="Enter game name"
                                className="h-10 bg-gray-600 text-gray-100"
                              />
                            </div>
                            <div className="space-y-1">
                              <Label>Agent Link</Label>
                              <Input
                                value={game.agentLink || ''}
                                onChange={(e) => handleGameChange(index, 'agentLink', e.target.value)}
                                placeholder="Enter agent link"
                                className="h-10 bg-gray-600 text-gray-100"
                              />
                            </div>
                            <div className="space-y-1">
                              <Label>Game Image</Label>
                              <div className="relative w-[225px] h-[225px] rounded-lg overflow-hidden bg-gray-800">
                                {game.imageUrl ? (
                                  <img
                                    src={game.imageUrl}
                                    alt={game.name}
                                    className="w-full h-full object-cover"
                                  />
                                ) : (
                                  <div className="w-full h-full bg-gray-600 flex items-center justify-center">
                                    <Upload className="h-5 w-5 text-gray-500" />
                                  </div>
                                )}
                                <input
                                  type="file"
                                  className="absolute inset-0 opacity-0 cursor-pointer"
                                  accept="image/*"
                                  onChange={(e) => handleGameImageUpload(e, index)}
                                  disabled={isUploading}
                                />
                              </div>
                              {uploadProgress[index] !== undefined && uploadProgress[index] < 100 && (
                                <Progress value={uploadProgress[index]} />
                              )}
                            </div>
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </div>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="messages">
            <AttentionMessagesSection 
              settings={settings} 
              setSettings={setSettings} 
            />
          </TabsContent>
        </Tabs>

        <div className="fixed bottom-0 left-0 right-0 p-2 bg-gray-800 shadow-md border-t border-gray-700 max-w-[510px] mx-auto">
          <div className="flex items-center gap-2 max-w-lg mx-auto">
            <Button 
              onClick={handleSave}
              disabled={isSaving || isUploading}
              className="flex-1 h-10"
            >
              {isSaving ? 'Saving...' : 'Save Changes'}
            </Button>
            {saveStatus && (
              <div className="absolute -top-10 left-2 right-2 bg-gray-700 p-1 rounded shadow">
                <Alert variant={saveStatus === 'success' ? 'default' : 'destructive'}>
                  <AlertDescription>
                    {saveStatus === 'success' ? 'Settings saved successfully!' : 'Failed to save settings'}
                  </AlertDescription>
                </Alert>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
} 