import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { AdminPage } from './pages/AdminPage';
import { MainLayout } from './components/layout/MainLayout';
import { Header } from './components/header/Header';
import { GameGrid } from './components/games/GameGrid';
import { AttentionBanner } from './components/common/AttentionBanner';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { SettingsProvider } from './contexts/SettingsContext';

function App() {
  return (
    <Router>
      <SettingsProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            } 
          />
          
          <Route path="/" element={
            <MainLayout>
              <div className="flex flex-col">
                <Header />
                <AttentionBanner />
                <GameGrid />
              </div>
            </MainLayout>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </SettingsProvider>
    </Router>
  );
}

export default App;
