import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Plus, Trash2 } from 'lucide-react';
import { Settings } from '../../types';

interface AttentionMessagesSectionProps {
  settings: Settings;
  setSettings: React.Dispatch<React.SetStateAction<Settings>>;
}

export function AttentionMessagesSection({ settings, setSettings }: AttentionMessagesSectionProps) {
  const addMessage = () => {
    setSettings(prev => ({
      ...prev,
      attentionMessages: [...(prev.attentionMessages || []), '']
    }));
  };

  const removeMessage = (index: number) => {
    setSettings(prev => ({
      ...prev,
      attentionMessages: prev.attentionMessages?.filter((_, i) => i !== index) || []
    }));
  };

  const updateMessage = (index: number, value: string) => {
    setSettings(prev => {
      const newMessages = [...(prev.attentionMessages || [])];
      newMessages[index] = value;
      return { ...prev, attentionMessages: newMessages };
    });
  };

  return (
    <Card className="bg-gray-800 shadow-lg">
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Attention Messages</CardTitle>
        <Button onClick={addMessage} variant="outline" size="sm">
          <Plus className="h-4 w-4 mr-1" />
          Add Message
        </Button>
      </CardHeader>
      <CardContent className="space-y-4">
        {(!settings.attentionMessages || settings.attentionMessages.length === 0) ? (
          <div className="text-center p-4 text-gray-400">
            No messages added yet
          </div>
        ) : (
          settings.attentionMessages.map((message, index) => (
            <div key={index} className="flex gap-2 items-center">
              <Input
                value={message}
                onChange={(e) => updateMessage(index, e.target.value)}
                placeholder="Enter attention message"
                className="flex-1 bg-gray-700 text-gray-100"
              />
              <Button
                variant="destructive"
                size="icon"
                onClick={() => removeMessage(index)}
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          ))
        )}
      </CardContent>
    </Card>
  );
} 