import { useState, useEffect } from 'react';
import { useSettings } from '../../contexts/SettingsContext';

export function AttentionBanner() {
  const { settings } = useSettings();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const messages = settings.attentionMessages && settings.attentionMessages.length > 0 
    ? settings.attentionMessages 
    : ["若实名和银行账号名称不一致，公司有权利拒绝洗分申请", "免费洗分次数 3次 ， 最少提现金额为 100.00"];

  useEffect(() => {
    if (messages.length <= 1) return;

    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentIndex((prev) => (prev + 1) % messages.length);
        setIsTransitioning(false);
      }, 500);
    }, 4000);

    return () => clearInterval(timer);
  }, [messages.length]);

  if (messages.length === 0) return null;

  return (
    <div className="bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-3 mx-2 mt-2 mb-2 overflow-hidden h-[52px]">
      <div className="flex items-center justify-center gap-2 h-full">
        <svg 
          className="w-5 h-5 text-yellow-500 flex-shrink-0" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
          />
        </svg>
        <div className="relative flex-1 h-full flex items-center justify-center">
          <p 
            className={`text-sm text-yellow-500/90 transition-all duration-500 absolute text-center ${
              isTransitioning 
                ? 'opacity-0 transform translate-y-2' 
                : 'opacity-100 transform translate-y-0'
            }`}
          >
            {messages[currentIndex]}
          </p>
        </div>
      </div>
    </div>
  );
} 